<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 17 17"
  >
    <g
      fill="none"
      fill-rule="evenodd"
    >
      <g
        :fill="color"
        fill-rule="nonzero"
      >
        <path
          d="M690.949 724.112l-4.805 2.936c-.093.056-.17.135-.226.228l-2.783 4.72c-.153.258-.112.587.098.8.21.216.54.26.799.114l4.566-2.579c.273-.154.393-.221.475-.399l2.798-4.927c.149-.263.101-.593-.116-.804-.217-.21-.548-.246-.806-.089zm-2.957 5.04l-2.46 1.388 1.444-2.448 2.476-1.512-1.46 2.571zm7.975-.663c0 1.674-.497 3.303-1.437 4.709-.52.712-1.562.012-1.102-.737 3.198-4.67-.293-11.178-5.945-11.13-3.946 0-7.157 3.21-7.157 7.158-.048 5.512 6.176 9.018 10.86 6.127.313-.19.72-.09.91.223.19.313.09.72-.223.91-3.237 2.013-7.73 1.482-10.388-1.262-5.32-5.33-1.531-14.475 5.998-14.482 4.56-.094 8.578 3.923 8.484 8.484z"
          transform="translate(-679 -720)"
        />
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    size: {
      type: Number,
      default: 16
    },
    variant: {
      type: String,
      default: 'dark' // light, dark
    },
  },
  setup(props) {
    const color = (() => {
      if (props.variant === 'dark') {
        return '#151515'
      }
      if (props.variant === 'light') {
        return '#a8a8a8'
      }

      // Default: light
      return '#a8a8a8'
    })()

    const width = props.size
    const height = props.size

    return {
      color,
      width,
      height,
    }
  }
})
</script>

