export type Option = {
  label: string;
  value: string | null;
};

type UseOptionSelectorQueryParam = {
  label: string,
  defaultValue: string | null;
};

export const useSelectorToQueryParam = (props: UseOptionSelectorQueryParam) => {
  const route = useRoute()
  const router = useRouter()

  const label = props.label
  const defaultValue: Option = {
    label,
    value: props.defaultValue
  }

  const optionSelected = ref<Option>(defaultValue)
  const hookEnabled = ref(false)

  let lastQueryValue: string | null = ''
  const onValueChangedEvent = ref<VoidFunction>(() => { /** void */ })

  function onRouteQueryChanged(query: any) {
    let newValue = defaultValue
    if (query[label]) {
      newValue = { label: label, value: query[label] }
    }
    optionSelected.value = newValue

    if (lastQueryValue != newValue.value) {
      lastQueryValue = newValue.value

      onValueChangedEvent.value()
    }
  }

  function onValueChanged(fn: VoidFunction) {
    onValueChangedEvent.value = fn
  }

  function pushQueryParam(value: string | null | undefined) {
    const finalQuery: Record<string, string | null> = {}

    Object.entries(route.query)
      .filter(([key, ]) => label !== key)
      .forEach(([key, value]) => {finalQuery[key] = value as string | null})

    if (value) {
      finalQuery[label] = value
    }

    router.replace({query: finalQuery})
  }

  onMounted(() => {
    hookEnabled.value = true
    onRouteQueryChanged(route.query)
  })

  watch(
    () => route.query,
    (query: Record<string, string>) => {
      if (hookEnabled.value) {
        onRouteQueryChanged(query)
      }
    },
    { deep: true, immediate: true }
  )

  return {
    optionSelected,
    pushQueryParam,

    // Eventos
    onValueChanged,
  }
}
